import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/contactForm'

export default ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.page.frontmatter.title}
        seoTitle={data.page.frontmatter.seo_title}
        description={data.page.frontmatter.seo_description}
      />
      <ContactForm data={data.page} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        seo_title
        seo_description
        headline
        featured_image {
          src {
            publicURL
            childImageSharp {
              fixed(width: 250) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          alt
        }
      }
    }
  }
`
