import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import classNames from 'classnames'
import { UsaStates } from 'usa-states'

import styles from './contactForm.module.css'

import Image from './image'

const contactFormSchema = Yup.object().shape({
  firstname: Yup.string(),
  email: Yup.string()
    .email()
    .required(),
  message: Yup.string().required(),
  state: Yup.string().required(),
  kris_brand_engagement: Yup.boolean().required(),
})

const { states } = new UsaStates()

const ContactForm = ({ data }) => {
  const { frontmatter } = data
  const [alert, setAlert] = useState(null)

  return (
    <div className={classNames('bg-paint-top-blue', styles.root)}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className={classNames('title', styles.title)}>
            {frontmatter.headline ? frontmatter.headline : frontmatter.title}
          </h1>
          <Formik
            initialValues={{
              firstname: '',
              email: '',
              message: '',
              state: '',
              kris_brand_engagement: true,
            }}
            validationSchema={contactFormSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setAlert(null)
              fetch(`${process.env.GATSBY_LAMBDA_API_URL}hubspot`, {
                method: 'POST',
                body: JSON.stringify({
                  formGuid: '5f386e9e-078b-4f8b-99d2-b3d9973bbe63',
                  data: values,
                }),
              })
                .then(response => {
                  if (response.status === 200) {
                    return response.json()
                  }

                  return Promise.reject(response)
                })
                .then(json => {
                  const message = json.inlineMessage
                    ? json.inlineMessage
                    : 'Thanks for contacting KRIS wine!'
                  setAlert({
                    type: 'success',
                    message: <p>{message}</p>,
                  })
                  resetForm()
                })
                .catch(error => {
                  console.error('Error response', error)
                  setAlert({
                    type: 'error',
                    message: <p>{error.statusText}</p>,
                  })
                })
                .finally(() => {
                  setSubmitting(false)
                })
            }}
          >
            {({ isValid, isSubmitting }) => (
              <Form
                className="form"
                aria-label="KRIS wine contact form"
                tabIndex={0}
              >
                {alert && (
                  <div
                    className={classNames('alert', `alert--${alert.type}`)}
                    aria-live="assertive"
                  >
                    {alert.message}
                  </div>
                )}
                <div className="form__row-2col">
                  <div className="form__group">
                    <label htmlFor="input-name">Name</label>
                    <Field id="input-name" name="firstname" className="input" />
                    <ErrorMessage
                      name="firstname"
                      component="div"
                      className="input-error"
                      aria-live="assertive"
                    />
                  </div>
                  <div className="form__group">
                    <label htmlFor="input-email">
                      Email <sup>*</sup>
                    </label>
                    <Field
                      id="input-email"
                      type="email"
                      name="email"
                      className="input"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="input-error"
                      aria-live="assertive"
                    />
                  </div>
                </div>
                <div className="form__group">
                  <label htmlFor="input-message">
                    Message <sup>*</sup>
                  </label>
                  <Field
                    id="input-message"
                    name="message"
                    component="textarea"
                    rows={5}
                    cols={1}
                    className="input"
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="input-error"
                    aria-live="assertive"
                  />
                </div>
                <div className="form__group">
                  <label htmlFor="input-state">
                    State <sup>*</sup>
                  </label>
                  <Field
                    id="input-state"
                    name="state"
                    component="select"
                    className="input input--select"
                  >
                    <option value="">Select state</option>
                    {states.map((state, index) => (
                      <option key={index} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="input-error"
                    aria-live="assertive"
                  />
                </div>
                <button
                  className="button button--green button--wide"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>

        {frontmatter.featured_image && (
          <figure className={styles.image}>
            <Image
              node={frontmatter.featured_image.src}
              alt={frontmatter.featured_image.alt}
            />
          </figure>
        )}
      </div>
    </div>
  )
}

ContactForm.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactForm
